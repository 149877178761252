import type { LogoProps } from "@/components/SiteLogo/components/logo-props.types";
import clsx from "clsx";
export default function KForumLogo({
  className,
  contextId = "kforum",
  secondaryClassName,
  size = "lg"
}: LogoProps) {
  const gradientFrom = "hsl(var(--theme-color-site-logo-gradient-from))";
  const gradientTo = "hsl(var(--theme-color-site-logo-gradient-to))";
  const isLarge = size === "lg";
  return <svg aria-hidden="true" className={clsx(className, "relative", isLarge ? "lg:-mt-2 lg:h-8 lg:translate-y-[19.75%] xl:h-9" : "lg:h-4 lg:translate-y-[8.75%]")} height="80" viewBox="0 0 160 40" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="KForumLogo" data-sentry-source-file="KForumLogo.component.tsx">
      <linearGradient gradientUnits="userSpaceOnUse" id={`SVGID_1_${contextId}`} x1="0.5907" x2="157.0347" y1="19.9905" y2="19.9905" data-sentry-element="linearGradient" data-sentry-source-file="KForumLogo.component.tsx">
        <stop offset="0" stopColor={gradientFrom} data-sentry-element="stop" data-sentry-source-file="KForumLogo.component.tsx" />
        <stop offset="1" stopColor={gradientTo} data-sentry-element="stop" data-sentry-source-file="KForumLogo.component.tsx" />
      </linearGradient>
      <path className={secondaryClassName} d="M16.382-.001C9.605-.001 3.609 3.388 0 8.561h8.602v10.206l7.66-10.206h12.091l-9.324 10.747 10.387 13.053H17.084L8.602 21.174v11.189h-7.9c3.649 4.632 9.324 7.619 15.68 7.619 11.028 0 19.991-8.943 19.991-19.991S27.43-.001 16.382-.001z" fill={`url(#SVGID_1_${contextId})`} data-sentry-element="path" data-sentry-source-file="KForumLogo.component.tsx" />
      <linearGradient gradientUnits="userSpaceOnUse" id={`SVGID_2_${contextId}`} x1="0.5907" x2="157.0347" y1="20.4903" y2="20.4903" data-sentry-element="linearGradient" data-sentry-source-file="KForumLogo.component.tsx">
        <stop offset="0" stopColor={gradientFrom} data-sentry-element="stop" data-sentry-source-file="KForumLogo.component.tsx" />
        <stop offset="1" stopColor={gradientTo} data-sentry-element="stop" data-sentry-source-file="KForumLogo.component.tsx" />
      </linearGradient>
      <path className={secondaryClassName} d="M50.335 32.086H44.18V8.895h15.006v4.487h-8.852v5.82h8.518v4.426h-8.518v8.458z" fill={`url(#SVGID_2_${contextId})`} data-sentry-element="path" data-sentry-source-file="KForumLogo.component.tsx" />
      <linearGradient gradientUnits="userSpaceOnUse" id={`SVGID_3_${contextId}`} x1="0.5907" x2="157.0347" y1="20.5662" y2="20.5662" data-sentry-element="linearGradient" data-sentry-source-file="KForumLogo.component.tsx">
        <stop offset="0" stopColor={gradientFrom} data-sentry-element="stop" data-sentry-source-file="KForumLogo.component.tsx" />
        <stop offset="1" stopColor={gradientTo} data-sentry-element="stop" data-sentry-source-file="KForumLogo.component.tsx" />
      </linearGradient>
      <path className={secondaryClassName} d="M72.89 8.561c7.397 0 11.186 4.578 11.186 12.005 0 7.306-4.062 12.005-11.186 12.005-7.609 0-11.277-4.608-11.277-12.005 0-7.276 4.244-12.005 11.277-12.005zm4.517 12.005c0-5.123-1.879-7.7-4.547-7.7-3.062 0-4.547 2.577-4.547 7.7 0 5.153 1.334 7.639 4.547 7.639 3.062 0 4.547-2.516 4.547-7.639z" fill={`url(#SVGID_3_${contextId})`} data-sentry-element="path" data-sentry-source-file="KForumLogo.component.tsx" />
      <linearGradient gradientUnits="userSpaceOnUse" id={`SVGID_4_${contextId}`} x1="0.5907" x2="157.0347" y1="20.4903" y2="20.4903" data-sentry-element="linearGradient" data-sentry-source-file="KForumLogo.component.tsx">
        <stop offset="0" stopColor={gradientFrom} data-sentry-element="stop" data-sentry-source-file="KForumLogo.component.tsx" />
        <stop offset="1" stopColor={gradientTo} data-sentry-element="stop" data-sentry-source-file="KForumLogo.component.tsx" />
      </linearGradient>
      <path className={secondaryClassName} d="M96.023 23.81h-2.698v8.276h-6.154V8.895H96.6c1.394 0 3.183.121 4.759.758 2.365.94 4.335 2.728 4.335 6.214 0 3.305-2.031 5.366-4.244 6.366v.061l5.245 9.792h-6.518l-4.154-8.276zm-.273-4.183c1.88 0 3.699-.819 3.699-3.365 0-2.759-2.243-3.305-3.638-3.305h-2.485v6.67h2.424z" fill={`url(#SVGID_4_${contextId})`} data-sentry-element="path" data-sentry-source-file="KForumLogo.component.tsx" />
      <linearGradient gradientUnits="userSpaceOnUse" id={`SVGID_5_${contextId}`} x1="0.5907" x2="157.0347" y1="20.7329" y2="20.7329" data-sentry-element="linearGradient" data-sentry-source-file="KForumLogo.component.tsx">
        <stop offset="0" stopColor={gradientFrom} data-sentry-element="stop" data-sentry-source-file="KForumLogo.component.tsx" />
        <stop offset="1" stopColor={gradientTo} data-sentry-element="stop" data-sentry-source-file="KForumLogo.component.tsx" />
      </linearGradient>
      <path className={secondaryClassName} d="M108.698 8.895h6.154l-.03 13.642c0 1.394.03 3.001.727 3.941.728.91 1.698 1.304 2.972 1.304 1.394 0 2.425-.394 3.122-1.304.667-.94.606-2.546.606-3.941V8.895h6.063V23.78c0 5.881-3.941 8.792-9.852 8.792-5.912 0-9.762-2.911-9.762-8.792V8.895z" fill={`url(#SVGID_5_${contextId})`} data-sentry-element="path" data-sentry-source-file="KForumLogo.component.tsx" />
      <linearGradient gradientUnits="userSpaceOnUse" id={`SVGID_6_${contextId}`} x1="0.5907" x2="157.0347" y1="20.4903" y2="20.4903" data-sentry-element="linearGradient" data-sentry-source-file="KForumLogo.component.tsx">
        <stop offset="0" stopColor={gradientFrom} data-sentry-element="stop" data-sentry-source-file="KForumLogo.component.tsx" />
        <stop offset="1" stopColor={gradientTo} data-sentry-element="stop" data-sentry-source-file="KForumLogo.component.tsx" />
      </linearGradient>
      <path className={secondaryClassName} d="M131.891 8.895h8.822l3.274 10.246c.606 1.849.91 3.638.91 3.638h.091s.333-1.788.909-3.668l3.184-10.216h8.822v23.191h-6.033V14.897h-.091s-.606 2.638-1.485 5.578l-3.517 11.611h-4.274l-3.638-11.611c-.94-2.971-1.424-5.548-1.424-5.548h-.091v17.158h-5.457V8.895z" fill={`url(#SVGID_6_${contextId})`} data-sentry-element="path" data-sentry-source-file="KForumLogo.component.tsx" />
    </svg>;
}